<template>
  <div>
    <b-link :to="{ name: 'addUser' }">Ajouter un utilisateur</b-link>
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
              v-model="searchedTerm"
              placeholder="Recherche"
              @keyup.enter="searchUsersByTerm"
          />
          <b-input-group-append>
            <b-button variant="outline-primary" @click="searchUsersByTerm">
              <font-awesome-icon icon="search"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col v-if="users.length > 0">
        <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageSize"
            @change="loadUsers"
        />
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col v-if="loading">
        <UserListSkeleton/>
      </b-col>
      <b-col v-else-if="users.length > 0">
        <UserList :users="users"/>
      </b-col>
      <b-col v-else>
        <span>Aucun utilisateur ne correspond à votre recherche</span>
      </b-col>
    </b-row>
    <b-row v-if="users.length > 0">
      <b-col>
        <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageSize"
            @change="loadUsers"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";
import UserList from "@/components/users/UserList";
import UserListSkeleton from "@/components/users/UserListSkeleton.js";

export default {
  components: {
    UserList,
    UserListSkeleton,
  },
  data() {
    return {
      currentPage: 1,
      loading: true,
      searchedTerm: null,
    };
  },
  computed: {
    ...mapState({
      total: (state) => state.users.total,
      pageSize: (state) => state.users.pageSize,
      users: (state) => state.users.all,
    }),
  },
  methods: {
    async loadUsers(page = 1) {
      this.loading = true;
      try {
        await this.$store.dispatch("users/fetchUsers", {
          page: page,
          search: this.searchedTerm ?? null,
        });
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la récupération des utilisateurs",
          text: e.message,
        });
        return;
      }
      this.loading = false;
    },
    searchUsersByTerm() {
      this.currentPage = 1;
      this.loadUsers(this.currentPage);
    },
  },
  async created() {
    await this.loadUsers();
  },
};
</script>

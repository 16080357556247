<template>
  <b-table
      hover
      striped
      borderless
      head-variant="light"
      :items="users"
      :fields="fields"
  >
    <template #cell(fullName)="user">
      {{ user.item.lastName.toUpperCase() }} {{ user.item.firstName }}
    </template>

    <template #cell(admin)="user">
      <div>
        <font-awesome-icon
            v-if="user.item.admin"
            icon="check-circle"
            :style="{ color: 'green' }"
        />
      </div>
    </template>

    <template #cell(edit)="user">
      <div class="row justify-content-end mr-0">
        <EditDeleteButtonGroup
            @edit="editUser(user.item)"
            @delete="deleteUser(user.item)"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import EditDeleteButtonGroup from "@/components/buttons/EditDeleteButtonGroup";

export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {key: "fullName", label: "NOM Prénom"},
        {key: "email", label: "Email"},
        {key: "admin", label: "Administrateur"},
        {key: "edit", label: ""},
      ],
    };
  },
  components: {
    EditDeleteButtonGroup,
  },
  methods: {
    editUser(user) {
      this.$router.push({
        name: "user",
        params: {uid: user.uid},
      });
    },
    async deleteUser(user) {
      try {
        await this.$store.dispatch("users/deleteUser", user.uid);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la suppression de l'utilisateur",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Utilisateur supprimé",
      });
    },
  },
};
</script>


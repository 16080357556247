import Vue from 'vue'

import {BSkeleton, BTableSimple, BTbody, BTd, BTh, BThead, BTr} from 'bootstrap-vue'

export default Vue.component('SkeletonTable', {
    functional: true,
    props: {
        fields: {
            type: Array,
            required: true,
        },
        rows: {
            type: Number,
            required: true,
        },
        tableProps: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    render(h, {props}) {

        let headProps = {}
        let tableProps = {}

        // split BThead props from BTable props
        for (let name in props.tableProps) {
            if (name.startsWith('head')) {
                headProps[name] = props.tableProps[name]
            } else {
                tableProps[name] = props.tableProps[name]
            }
        }

        props.tableProps = tableProps

        // header
        const $headColumns = [];
        props.fields.forEach(field => {
            $headColumns.push(h(BTh, field.name))
        });
        const $headTr = h(BTr, $headColumns)
        const $thead = h(BThead, {props: {...headProps}}, [$headTr])

        // body
        const $bodyColumns = []
        props.fields.forEach((field) => {
            $bodyColumns.push(h(BTd, [
                h(BSkeleton, {props: field.skeletonProps ?? {}})
            ]))
        })
        const $row = h(BTr, $bodyColumns)
        const $tbody = h(BTbody, Array(props.rows).fill($row))

        return h(BTableSimple, {props: {...tableProps}}, [$thead, $tbody])
    },
})
<template>
  <b-button-group>
    <EditButton class="no-border" @edit="$emit('edit')"/>
    <DeleteButton
        class="no-border"
        @delete="$emit('delete')"
        :prevent="preventDelete"
    />
  </b-button-group>
</template>

<script>
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";

export default {
  components: {
    EditButton,
    DeleteButton,
  },
  props: {
    preventDelete: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
